/* Common styles */
.add-your-home-stay {
    margin-top: 5rem !important;
}

.Toastify__toast-theme--light {
    margin-top: 61px !important;
    z-index: 9999px
}

@media (max-width: 991.98px) {
    .add-homestay-button {
        height: 50px;
        /* Height for small screens (sm and below) */
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .add-homestay-button {
        height: 50px;
        /* Height for large screens (lg and up) */
    }
}

#Landing_form_container {
    padding: 20px;
    height: 75vh;
    z-index: 9999;
}

.add-home-stay-form-img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
    overflow: hidden;
}

.add-home-stay-form-img::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5));
}

.add-home-stay-form-img img {
    width: 100%;
    height: auto;
    display: block;
    filter: grayscale(100%);
}

.title {
    font-family: Trebuchet MS, sans-serif;
    color: #000000;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
}

.child-add-your-home-stay {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
    margin: auto;
}

.highlighted:hover {
    z-index: 99999;
}

.bg_style {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-size: cover;
    background-position: center;
    z-index: -9999;
}

@media only screen and (max-width: 600px) {

    #Landing_form_container {
        padding: 0px !important;
        height: 60vh;
        z-index: 9999;
    }

    .bg_style {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 85%;
        background-size: cover;
        background-position: center;
        z-index: -9999;
    }

}

/* Media query for all small screens */
@media only screen and (max-width: 767px) {
    .bi-people-fill {
        padding-right: 10px;
        color: rgb(0, 0, 0);
    }

    .check-form {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    .sub_child {
        border-radius: 2rem;
        /* padding: 1rem; */
        margin-top: -2rem;
    }

    .flex-item {
        flex-basis: 100%;
        margin: 5px;
    }
}

/* Media query for iPad landscape and portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .check-form {
        /* background: rgb(244, 244, 244); */
        border-radius: 100px;
        /* padding: 10px 30px; */
        /* display: flex;
        flex-wrap: wrap; */
        justify-content: center;
        align-items: center;
        min-height: 110px;

    }
}

/* General media query for larger screens */
@media only screen and (min-width: 1068px) {
    .sub_child {
        border-radius: 2rem;
        /* border: 2px solid; */
        /* overflow: hidden; */
        padding: 10px;
        margin: auto;
    }

    .check-form {
        background: rgb(255, 255, 255);
        border-radius: 100px;
        /* padding: 10px 30px; */
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        /* min-height: 110px; */
        max-width: 1000px;
        min-width: 800px;
    }

    .flex-item {
        flex-basis: 100%;
        /* border: 2px solid red; */
    }

    .flex-item:nth-child(1) {
        flex-basis: 51%;
        /* border: 1px solid red; */
        /* margin: 0px 10px 10px -36px; */
    }

    .flex-item:nth-child(2) {
        flex-basis: 30%;
        /* margin: 10px; */

    }

    .flex-item:nth-child(3) {
        margin-left: 25px;
        flex-basis: 10%;
    }


    #Main-button {
        border: 1px solid;
        font-size: 18px;
    }

}

/* 
.dropdown{
    outline: none;
    border-radius: 100px;
    padding-right: 20px;
    min-height: 75px;
    background-color: white;
} */
.dropdown:focus {
    border: none;
}

.react-calendar__tile--active {
    background: #000000 !important;
    border-radius: 20px;
    color: white;
}

/* .bi-people-fill{
    position: absolute;
    top: 25%;
    left: 16%;
} */

.container-chat-form {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* border: 2px solid; */
    max-width: 300px;
    min-width: 300px;
    min-height: 350px;
    padding: 10px 25px;
    position: fixed;
    z-index: 999;
    top: 20%;

    background-color: rgb(215, 215, 215);
    /* left: 0; */
    right: 2%;
    border-radius: 10px;
}