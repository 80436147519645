:root {
    --primary-color: #000000;
    /* Change this to your primary color */
    --secondary-color: #ffffff;
    /* Change this to your secondary color */
}

.navbar-toggler {
    margin-right: 10px;
}

.img-class-view-details {
    height: 100px;
    width: 110px;
    margin-top: 10px;
}

.tag_view {
    /* width: 10%; */
    cursor: pointer;
    top: -11%;
    left: 0%;
    margin-left: -15px;
    position: absolute;
    left: 0;
    z-index: 1;

}

.home-stay-title {
    font-size: 1.5rem;
    /* margin-top: 10px; */
    font-weight: 600;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    font-family: Trebuchet MS, sans-seri;

}

.home-stay-name {
    font-size: 2rem;
    font-weight: 600;
    text-shadow: 0 1px 0 #ccc,
        0 2px 0 #c9c9c9,
        0 3px 0 #bbb,
        0 4px 0 #b9b9b9,
        0 5px 0 #aaa,
        0 6px 1px rgba(0, 0, 0, .1),
        0 0 5px rgba(0, 0, 0, .1),
        0 1px 3px rgba(0, 0, 0, .3),
        0 3px 5px rgba(0, 0, 0, .2),
        0 5px 10px rgba(0, 0, 0, .25),
        0 10px 10px rgba(0, 0, 0, .2),
        0 20px 20px rgba(0, 0, 0, .15);
}


.check-in-check-out-date {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin: 10px auto;
    /* Auto margin for centering horizontally */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}


#Main-button-details {
    /* margin: auto; */
    border: 1px solid var(--secondary-color);
    /* padding: 0px 40px; */
    display: grid;
    place-content: center;

    min-height: 55px;
    width: 100%;
    /* margin-top: 23px; */
    background-color: var(--primary-color);
    color: var(--secondary-color);
    /* text-align: center; */
}

.container-social {
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.bi-whatsapp:hover {
    color: #128c7e;
    cursor: pointer;
    transition-duration: .5s;

}

.bi-facebook:hover {
    color: #316FF6;
    cursor: pointer;
    transition-duration: .5s;

}

.bi-instagram:hover {
    color: #E1306C;
    cursor: pointer;
    transition-duration: .5s;

}

.bi-envelope:hover {
    color: rgb(154, 154, 154);
    cursor: pointer;
    transition-duration: .5s;

}

.bi-twitter-x:hover {
    color: #1DA1F2;
    cursor: pointer;
    transition-duration: .5s;

}

.bg-about-image {
    /* width: 100%; */
    border-radius: 15px;
    /* height: 60vh; */
}

.bg-image {
    border-radius: 10px;
    width: 90%;
    max-height: 90%;
    /* margin-top: 2px;; */
    margin: auto;
    display: block;
    object-fit: cover;
    filter: blur(.5px);
}



.container-Feed {
    background-color: aquamarine;
    border-radius: 20px;
}

.bi-hand-thumbs-down:hover {
    /* background-color: red; */
    color: red;
    cursor: pointer;
}

.bi-hand-thumbs-up:hover {
    /* background-color: red; */
    color: green;
    cursor: pointer;
}

.headings {
    font-weight: 600;
    font-size: 30px;
    font-family: Trebuchet MS, sans-seri;
    z-index: 99999;
}

.sub-heading {
    font-weight: 600;
    font-size: 18px;
    /* text-align: left; */
    /* padding-bottom: 2rem; */
    font-family: Trebuchet MS, sans-seri;
}

.top-destination {
    background-color: #ffffff;
    border-radius: 20px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


.advantages {
    margin: 1px;
}

.container-w-w-u {
    margin-bottom: 10px;
    margin-top: 30px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.city-img {
    position: relative;
    /* width: 40px; */
    height: 150px;
    /* opacity: 1; */
    width: 260px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.city-img-background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: filter 0.5s ease;
    /* Smooth transition effect */
    filter: none;
}

.city-img:hover .city-img-background {
    filter: blur(2px);
    /* Remove blur effect on hover */
}

.image-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
}



#Child_form {
    margin-top: 20%;
    /* width: 70%; */

}

.react-daterange-picker__wrapper {
    padding: 13px;

    padding-left: 1.5rem;
    border-radius: .5rem;
    background-color: #ffffff;
    border: none !important;
}

.myNewSwiper {
    height: 300px !important;
    padding: 0;
    margin: 0;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 30% !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}

@media only screen and (max-width: 765px) {
    .img-class-view-details {
        height: 100px;
        width: 110px;
        margin-top: 10px;
    }

    .tag_view {
        /* width: 10%; */
        cursor: pointer;
        top: -11%;
        left: 0%;
        margin-left: -15px;
        position: absolute;
        left: 0;
        z-index: 1;

    }

    .container-city {
        overflow: hidden;
        display: flex;
        /* justify-content: center; */
        overflow-x: scroll;
        margin-top: 40px;
        padding: 10px;
        max-height: auto;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .city-img {
        position: relative;
        /* width: 40px; */
        height: 20vh;
        /* opacity: 1; */
        min-width: 270px;
        margin: 10px;
        border-radius: 10px;
        overflow: hidden;
    }

    .top-destination {
        background-color: #ffffff;
        padding: 15px 0px;
    }

    .container-city {
        margin-top: 30px;
        padding: 10px;
        /* display: flex;
        overflow: scroll;
        justify-content: space-evenly;
        flex-wrap: nowrap; */
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .container-city::-webkit-scrollbar {
        display: none;
    }

    .city-img {
        /* position: relative; */

        margin-right: 10px;
        border-radius: 10px;
        overflow: hidden;
    }

    .Room_div {
        margin-top: 31px;
    }

    .Room_div {
        height: auto;
    }

    #Landing_form_container {
        /* padding: 20px; */
        height: 61vh;
        /* z-index: ; */
        z-index: 9999;
    }

    #Child_form {
        margin-top: 50%;
        width: 100%;

    }

    #Main-button-details {
        /* width: 100%; */
        border: 1px solid var(--secondary-color);
        padding: 0px;
        margin-top: 23px;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        /* text-align: center; */
    }

    .Main_title h1 {
        margin-top: 25px;
        font-family: Trebuchet MS, sans-seri;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 600;
        text-shadow: 1px 2px 17px rgb(99, 99, 99), 5px 4px 20px rgba(0, 0, 0, 1);
    }
}


/* For desktop only */
@media only screen and (min-width: 765px) {
    .img-class-view-details {
        height: 100px;
        width: 110px;
        margin-top: 26px;
    }

    .tag_view {
        /* width: 10%; */
        cursor: pointer;
        top: -11%;
        left: 0%;
        margin-left: -15px;
        position: absolute;
        left: 0;
        z-index: 1;

    }

    .about-items {
        gap: 10px;
        width: 100%;
        /* border: 1px solid green; */
        display: flex;
        flex-wrap: wrap;
        /* overflow-x: scroll; */
        /* justify-content: center; */
        align-items: center;
    }

    .container-city {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        padding: 10px;
        max-height: auto;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .Landing-width-adjustment {
        max-width: 1200px;
        margin: auto;
        /* border-rad; */
    }

    #Landing_form_container {
        margin-top: 3rem;
    }

    .top-destination {
        background-color: #ffffff;
        padding: 0px 0px;
    }

    .Room_div {
        margin-top: 80px;
    }

    .nav-form {
        border-right: 1px solid rgb(215, 215, 215);
        height: 90vh;
        padding: 20px 0px;
    }

    .Room_div {
        height: 100vh;
    }

    .nav-form-parent {
        position: -webkit-sticky;
        position: sticky;
        height: 90vh;
        top: 10%;
        border-radius: 15px;
        margin: 20px 0px;
        /* Define the top position once */
        left: 0;
        /* Added a unit */
        /* width: 20rem; */
        border: 2px solid rgb(233, 233, 233);
        ;
    }

    .Main_title h1 {
        font-family: Trebuchet MS, sans-seri;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 2.5rem;
        font-weight: 600;
        text-shadow: 1px 2px 17px rgb(99, 99, 99), 5px 4px 20px rgba(0, 0, 0, 1);
    }
}




#homestay {

    color: rgb(255, 0, 0);
    /* text-shadow: 0 1px 0 #ccc,
        0 2px 0 #c9c9c9,
        0 3px 0 #bbb,
        0 4px 0 #b9b9b9,
        0 5px 0 #aaa,
        0 6px 1px rgba(0, 0, 0, .1),
        0 0 5px rgba(0, 0, 0, .1),
        0 1px 3px rgba(0, 0, 0, .3),
        0 3px 5px rgba(0, 0, 0, .2),
        0 5px 10px rgba(0, 0, 0, .25),
        0 10px 10px rgba(0, 0, 0, .2),
        0 20px 20px rgba(0, 0, 0, .15); */
}

.Notify-div {
    margin-top: 5rem;
    width: 100%;
}

/* CHAT  */
.gif-img-div {
    border-radius: 50%;
    /* border: 2px solid red; */
    overflow: hidden;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-chat {
    background-color: none;
    transform: scaleX(-1);
    height: 200%;
    width: 200%;
    object-fit: cover;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}

.off-main-outline {
    position: absolute; /* Relative to the nearest positioned ancestor */
    top: -60px; /* Adjust for distance from bottom */
    left: 50px; /* Adjust for distance from left */
    background-color: #ff5722; /* Bright background color */
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure it appears on top */
    transform: rotate(-10deg); /* Slight tilt for a fun effect */
    cursor: pointer;
    transition: transform 0.3s ease, left 0.3s ease, top 0.3s ease;
}
/* .chat-main-outline {
    margin-right: -2rem;
    cursor: pointer;
    position: fixed;
    top: 70%;
    right: 0;
    z-index: 9999;
    transition: margin-right 0.5s ease;
} */

.off-main-outline:hover {
    transform: rotate(-10deg) scale(1.1); /* Enlarge slightly on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .off-main-outline {
        top: 70%; /* Adjust for smaller screens */
        left: 0; /* Reduced left distance */
        font-size: 16px; /* Slightly smaller font size */
        padding: 8px 16px; /* Adjust padding */
    }
}

@media (max-width: 480px) {
    .off-main-outline {
        position: fixed;
        top: 70%; /* Adjust for very small screens */
        left: 0; /* Reduced left distance */
        font-size: 14px; /* Further reduced font size */
        padding: 6px 12px; /* Adjust padding */
    }
}

.off-main-outline:hover {
    transform: rotate(-10deg) scale(1.1); /* Enlarge slightly on hover */
    transition: transform 0.3s ease;
}
.chat-main-outline {
    margin-right: -2rem;
    cursor: pointer;
    position: fixed;
    top: 70%;
    right: 0;
    z-index: 9999;
    transition: margin-right 0.5s ease;
}

.chat-main-outline:hover {
    margin-right: 0rem;
}

.flag-button {
    position: relative;
    padding: 10px 40px 10px 20px;
    /* Increased padding for text and flag */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

.flag-text {
    position: absolute;
    top: -10px;
    right: -30px;
    /* Adjust this value based on the size of your flag */
    padding: 5px;
    background-color: #ff6347;
    /* Choose a color for your flag */
    color: white;
    border-radius: 3px;
    font-size: 12px;
}

.flag-button::after {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #007bff;
}

.about-items {
    gap: 10px;
    width: 100%;
    /* border: 1px solid green; */
    display: flex;
    overflow-x: scroll;
    /* justify-content: center; */
    align-items: center;
}


/* Hide scrollbar for IE, Edge and Firefox */

.about-item {
    /* border: 1px solid rgb(26, 26, 26); */
    border-radius: 5px;
    min-width: 220px;
    height: 50px;
    display: flex;
    /* justify-content: space-around; */
    text-align: center;

    align-items: center;
}

.about-content {
    font-size: 14px;
}

.about-icon-div {
    margin: 10px 10px;
    /* border: 1px solid red; */
    height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffff;
    /* width: 60px; */
    border-radius: 50%;
}

/* Styles/Landing.css */
.scroll-container {
    position: relative; /* Parent container for positioning */
}
