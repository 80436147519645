#monal-img-container {
    position: relative;
    width: 100%;
    /* border: 2px solid; */
    height: 80% !important;
    margin: 0 auto;
}

#monal_img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
}

.monal-content {
    position: absolute;
    border-radius: 10px;
    text-align: left;
    width: 600px;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 20px;
    background: rgba(58, 58, 58, 0.267);
    /* Adds a semi-transparent background for better readability */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .monal-content {
        width: 500px;
        left: 70%;
    }
}

@media (max-width: 992px) {
    .monal-content {
        width: 400px;
        left: 65%;
    }
}

@media (max-width: 768px) {
    .monal-content {
        width: 300px;
        left: 60%;
        top: 90%;
       height: 500px;
        overflow-y: scroll;
    }

    #monal-img-container {
        position: relative;
        width: 100%;
        /* border: 2px solid; */
        height: 80% !important;
        margin: 0 auto;
    }

    #monal_img {
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 576px) {
    .monal-content {
        width: 90%;
        left: 50%;
        top: 100%;
        background: rgba(58, 58, 58, 0.267);
    }

    #monal-img-container {
        position: relative;
        width: 100%;
        /* border: 2px solid; */
        height: 100% !important;
        margin: 0 auto;
        background: rgba(58, 58, 58, 0.267);
    }

}

@media (max-width: 480px) {
    .monal-content {
        /* position: relative; */
        width: 90%;
        left: 50%;
        top: 129%;
        padding: 10px;
        background: rgba(58, 58, 58, 0.744);
    }

    #monal-img-container {
        position: relative;
        width: 100%;
        /* border: 2px solid; */
        /* height: 00px !important; */
        margin: 0 auto;
    }

}