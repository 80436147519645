:root {
    --primary-color: #000000;
    /* Change this to your primary color */
    --secondary-color: #ffffff;
    --success: rgb(31, 137, 31);
    /* Change this to your secondary color */
}

.btn-success {
    background-color: var(--success);
}

.form-check-input {
    cursor: pointer !important;
}



.location-label {
    position: absolute;
    left: 35px;
    font-size: 14px;
    color: gray;
    top: 5px;
    /* left: auto; */
}

.date-label {
    position: absolute;
    /* right: 30%; */
    left: 35px;
    font-size: 14px;
    color: gray;
    top: 5px;
    /* left: auto; */
}

.hr-line-inform-nav {
    margin-top: 19px;
}


.result-text {
    font-family: Trebuchet MS, sans-seri;
    /* color: #ffffff; */
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 500;
    /* text-shadow: 1px 2px 17px rgb(99, 99, 99), 5px 4px 20px rgba(0, 0, 0, 1); */
}



.tag {
    /* width: 10%; */
    cursor: pointer;
    top: -11%;
    left: 0%;
    margin-left: -15px;
    position: absolute;
    left: 0;
    z-index: 1;

}

.card-title {
    margin-top: -6px;
    font-size: large;
    font-family: Trebuchet MS, sans-seri;
}

.carousel-control-prev,
.carousel-control-next {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    opacity: 0;
    transition: opacity 0.3s;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
    opacity: 1;
}


.carousel-control-prev {
    margin-top: 30%;
    margin-left: 6px;
}

.card-hover-effect:hover {
    /* border: 2px solid red; */
    cursor: pointer;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.border-none {
    border: none !important;
    background: none;
}

.carousel-control-next {
    margin-top: 30%;
    margin-right: 6px;
}


@media only screen and (max-width: 600px) {
    .react-daterange-picker {
        display: flex !important;
    }

    .room-image-search {
        height: 270px;
        object-fit: cover;
    }
    .room-image-search img{
        min-height: 270px;
        object-fit: cover;
    }

    .carousel-inner {
        height: 270px;
    }

    .room-image-search-details {
        height: 250px;
    }



    #exampleFormControlInput1 {
        padding: 20px;
        /* position: relative; */
        min-width: 200px;
        /* height: 112%; */
        font-size: 18px;
        padding-left: 2.1rem;
        padding-top: 50px;
        border-radius: 50px;
        background-color: rgb(255, 255, 255);
    }

    .react-daterange-picker__wrapper {
        border-radius: 50px;
        padding: 20px;
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap !important;
        background-color: #ffff;
    }

    .react-daterange-picker__wrapper:hover {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        cursor: pointer;
        border-radius: 50px;
        padding: 20px;
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap !important;
        background-color: #ffff;
    }

    .img-class {
        height: 100px;
        width: 110px;
        margin-top: 28px;
    }

    #Room_card {
        width: auto;
        height: 100vh;
        text-align: left;
        /* border: none; */

        overflow: hidden;
        background-color: rgb(238 238 238);
        /* margin-top: 50px; */
    }

    .scroll-to-show-all-rooms {
        overflow-y: scroll;
        position: relative;
        width: 94vh;
        /* Assuming you intended to set the width to 94% of the viewport height */
        max-height: calc(100vh - 100px);
        /* Adjust 100px according to the height of your footer */
    }

    .footer {
        /* Assuming your footer class is named 'footer' */
        height: 100px;
        /* Adjust the height according to your footer's height */
        /* Add any other necessary styling for your footer */
    }

    .card-body {
        border-top: none !important;
    }

    .nav-form-parent {
        margin: 0;
        height: auto;
        padding: 0;
    }
}

@media only screen and (min-width: 600px) {
  
    .room-image-search {
        height: 250px;
        object-fit: cover;
    }
    .room-image-search img{
        min-height: 250px;
        object-fit: cover;
    }

    .carousel-inner {
        height: 270px;
    }

    .room-image-search-details {
        height: 500px;
    }

    #exampleFormControlInput1 {
        padding: 20px;
        min-width: 215px;
        font-size: 18px;
        padding-left: 2.1rem;
        padding-top: 50px;
        border-radius: 50px;
        background-color: rgb(255, 255, 255);
    }

    .img-class {
        height: 100px;
        width: 110px;
    }

    .nav--bar {
        border-right: 2px solid rgb(235, 234, 234);
    }

    .react-daterange-picker {
        display: flex !important;
    }

    .react-daterange-picker__wrapper {
        border-radius: 50px;
        padding: 20px;
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap !important;
        background-color: #ffff;
    }

    .react-daterange-picker__wrapper:hover {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        cursor: pointer;
        border-radius: 50px;
        padding: 20px;
        display: flex;
        padding-top: 30px;
        flex-wrap: wrap !important;
        background-color: #ffff;
    }

    .scroll-to-show-all-rooms {
        overflow-y: scroll;
        height: 87vh;
        /* border: 1px solid red; */
    }

    .nav-form-parent {
        margin: 3rem 0rem;
        height: auto;
        padding: 0;
    }

    #Room_card {
        width: 100%;
        height: auto;
        text-align: left;
        overflow: hidden;
        /* margin:10px 0px; */
        background-color: rgb(238 238 238);
        /* margin-top: 50px; */
    }

}

.card-div {
    border: 1.5px solid transparent;
    transition: border .5s;
}

.card-div:hover {
    border-color: black;
}

.price-container {
    background-color: rgba(244, 244, 244, 0.862);
}

/* rgb(238 238 238) */
.Room_div {
    width: 100%;
    margin-top: 80px;
}

.rate-bar {
    height: 10px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgb(196, 196, 196);
}

.rate-bar-child {
    height: 10px;
    /* overflow: hidden; */
}