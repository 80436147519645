.swiper {
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
    max-height: 500px;
}

.mySwiper .swiper-slide {
    font-size: 18px;
    background: #fff;
    /* min-height: 480px; */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    
}

.mySwiper2 .swiper-slide img {
    /* border: 10px solid green; */
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the entire slide while maintaining aspect ratio */
    max-height: 100%;
}


/* For small Swiper -----------> */

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20% !important;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}

@media only screen and (max-width: 765px) {

    .swiper {
        width: 100%;
        height: 100% !important;
        margin-left: auto;
         max-height: 350px;
        margin-right: auto;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
    }

    .mySwiper {
        height: 20% !important;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }


    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
    }


}