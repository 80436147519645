:root {
  --primary-color: #000000;
  /* Change this to your primary color */
  --secondary-color: #ffffff;
  /* Change this to your secondary color */
}

html {
  scroll-padding-top: 200px;
  scroll-behavior: smooth;
}

body {
  /* font-family:sans-serif !important; */
  scroll-behavior: smooth;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
*{
  font-family: sans-serif !important;
}


.min-height {
  min-height: 100vh;
}

.Support {

  /* border: 2px solid red; */
  height: 100%;
  margin-top: 100px;
  max-width: 900px;
}

.support-child {
  margin-top: 30px;
  padding: 10px;
  /* border: 1px solid gray; */
  text-align: justify;
  text-justify: inter-word;

}


/* CSS */
.add-new-property {
  position: relative;
  overflow: hidden;
  font-weight: bold;
  /*  */
  color: #18181a;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 13px 13px 11px;
  text-decoration: none;
  cursor: pointer;
  /* background: #fff; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.add-new-property span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.add-new-property span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.add-new-property:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.add-new-property:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.add-new-property:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}


.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  /* Light dark background color */
}

.centered-gif {
  background-color: rgba(0, 0, 0, 0.3);
  /* border: 2px solid; */
  height: 100px;
  /* Increased height */
  width: 100px;
  /* Increased width */
  object-fit: cover;
  margin: auto;
  /* background-size: 200%; */
  border-radius: 50%;
  border: none;
}

.image-view-div {
  display: flex;
  place-content: center;
  /* border: 2px solid red; */
  width: 90%;
  height: 90%;
}

.App {
  text-align: center;
}

#light_bg {
  background-color: #EAEEFF;
}

#color {
  color: #000000;
}

body {
  background-color: rgb(255, 255, 255);
  height: 100vh;

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#profile_image {
  height: 50px;
  padding: 0;
  margin: 0;
  width: 50px;
  box-shadow: #ffffff 0px 0px 4px;
}



.login-main-div {
  height: 100vh;
  display: grid;
  place-content: center;
}


.input-css-custom {
  border: 1px solid #dadada;
  border-radius: 10px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

#Login_form {
  /* margin-top: 1rem; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: rgba(255, 0, 0, 0);
}

#text_color {
  font-family: Trebuchet MS, sans-seri;
  color: #2F3C7E;
  cursor: pointer;
}

#button {
  border: 1px solid var(--secondary-color);
  background-color: var(--primary-color);
  color: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

#button:hover {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--primary-color);
}


.navbar {
  border: 1px solid transparent;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

.footer {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}


.dropdown-menu {
  color: #ffffff;
}

.dropdown-item:hover {
  background-color: #ffffff;
  color: #000000;
}

.img-checkout-div {
  background-image: url('./Images/checkout/checkout.jpg');
  background-size: cover;
  background-position: center;
}

.checkout-img {
  background-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.modal-dialog {
  /* border: 1px solid red; */
  overflow: hidden;
  height: auto;
  width: auto;
}

.btn-close-checkout {
  position: absolute;
  right: 2%;
  top: 2%;
  /* left: 0; */
}

.checkout-container {
  /* height: 100vh;
  width: 100vh;
  border: 2px solid red; */
}

::-webkit-scrollbar {
  width: 10px;
  /* border-radius: 50%; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* border-radius: 50%; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loading-css {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.4);
  /* Semi-transparent black */
  z-index: -1;
  /* Behind other content */
  top: 0;
  left: 0;
  display: grid;
  place-content: center;

  /* filter: blur(4px); */
  z-index: 999999;

}


/* Desktop */
@media only screen and (min-width: 765px) {
  .view-in-detail-item {
    height: 445px;
    max-height: 445px;
  }

  .why-stay-with-uus {
    margin-top: 40px;
  }
}

/* Mobile */
@media only screen and (max-width: 765px) {

  .view-in-detail-item {
    height: 34vh;
  }

  .why-stay-with-uus {
    margin-top: 3px;
  }

}

/* Table Header */
table th {
  padding: 8px;
  /* Adjust padding as needed */
  text-align: center;
  font-size: 13px;
  background-color: #343434;
  /* Black background */
  color: #fff;
  /* White text color */
}

/* Alternate Row Colors */
table tr:nth-child(even) {
  color: black;
  background-color: #fff;
  /* White background for even rows */
}

table tr:nth-child(odd) {
  color: black;
  background-color: #f2f2f2;
  /* Light gray background for odd rows */
}

/* Hover Effect */
table tr:hover {
  background-color: #ddd;
  /* Light gray background on hover */
}

/* Table Cell Padding */
table td,
table th {
  padding: 8px;
  /* Adjust padding as needed */
}


.details-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}